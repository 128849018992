import moment from 'moment'
import dateFormat from 'dateformat'

/**
 * Convert a string to date
 * @param {string} num
 * @returns {String}
 */

const parseDate = (date) => {
	return dateFormat(moment(date, 'MM-DD-YY HH:mm:ss'), 'shortDate')
}

export default parseDate
