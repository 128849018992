import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {CopyToClipboard} from 'react-copy-to-clipboard'


import {
	Flex,
	Heading,
	Block,
	Paragraph,
} from 'reakit'

import { Container, Sidebar, Body, Footer } from '../../components/layouts'

import {
	StatCard,
	DynamicTable,
	DonationsChart,
	Card,
	Link,
	Loader,
} from '../../components/ui'

import {
	getProjects,
	changeSelectedProject,
	refreshToken
} from '../../factory'

import {
	DEEPLINK
} from '../../factory/types'

import currencyFormat from '../../util/currencyFormat'
import parseDate from '../../util/parseDate'

import Layout from '../../components/layout'

import theme from '../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			update: false,
			timeout: true
		}

		this.getProjects = this.getProjects.bind(this)
		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.updateComponent = this.updateComponent.bind(this)
		this.clickProject = this.clickProject.bind(this)
		this.renderSumPerDay = this.renderSumPerDay.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then(()=> {
			this.getProjects()
			this.getBodyWidth()
		}).catch((err) => console.log(err))


		window.addEventListener('resize', this.getBodyWidth.bind(this))

		setTimeout(()=>
			this.setState({timeout: false}),
		5000
		)
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.stats && nextProps.stats.sumPerDay) {
			if(nextProps.stats.sumPerDay.length !== this.props.stats.sumPerDay.length) {
				this.updateComponent()
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}


	updateComponent() {
		this.setState({update: !this.state.update})
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 280)
		})
	}

	getProjects() {
		this.props.onGetProjects(this.props.organization.id)
	}

	clickProject(row) {
		this.props.onSelectProject(row)
		window.location = window.location.origin + '/admin/project?projectId=' + row.id
	}

	renderSumPerDay() {
		const {sumPerDay, sumPerDayError} = this.props.stats && this.props.stats

		if (sumPerDayError) {
			return null
		}

		if (sumPerDay) {
			if (this.props.statsPending || sumPerDay.length <= 0) {
				return (
					<Block marginBottom="1em">
						<Loader />
					</Block>
				)
			}
			return (
				<DonationsChart data={sumPerDay} width={this.state.bodySize} />
			)
		}
		return (
			null
		)
	}

	render() {
		const {avgSum, quantity, sum, sumError, sumPerDayError, users} = this.props.stats && this.props.stats
		const {organization} = this.props

		const monthlyMin  = users.filter((user) => user.has_payment_account && user.monthly_minimum).reduce((a, b)=> a + (b.monthly_minimum || 0), 0)

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body showDate>
						<Flex margin="1em 0em" column alignItems="center" justifyContent="space-around">
							{!sumPerDayError && <Card >
								{this.renderSumPerDay()}
							</Card>}
							{organization && <div style={{ padding: 10, backgroundColor: theme.colors.crusta, width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
								<CopyToClipboard text={`${DEEPLINK}?organization_id=${organization.id}`}
									onCopy={() => this.setState({copied: true})}>
									<Heading as="h4" margin="0 0 0.5em 0" color="white">Send users directly to you: {DEEPLINK}
										<button style={{background: 'none', border: 'none'}}>
											<i className="fas fa-copy" style={{color: 'white', marginLeft: 10}}/>
										</button>
									</Heading>
								</CopyToClipboard>
								<Paragraph style={{color: 'white'}}>This link will send your donors directly to your organization in the Flourish App.</Paragraph>
							</div>}
						</Flex>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							<Flex row minHeight={'8em'} justifyContent="space-between" width="100%" marginBottom="1em">
								{this.props.organization && <StatCard
									title={'The Who'}
									stat={this.props.organization.donor_qty || 0}
									loading={!this.props.organization.donor_qty && this.props.organization.donor_qty !== 0}
									description={'Total Users'}
									totalCards={5}
								/>}
								{this.props.stats && <StatCard
									title={'The What'}
									stat={quantity || 0}
									loading={!quantity && quantity !== 0}
									description={'# Donations'}
									totalCards={5}
								/>}
								{this.props.stats && <StatCard
									title={'The How'}
									stat={avgSum || 0}
									loading={!avgSum && avgSum !== 0}
									type={'money'}
									description={'Avg. Donation'}
									totalCards={5}
								/>}
								{this.props.stats && <StatCard
									title={'The Impact'}
									stat={sum || 0}
									loading={!sum && sum !== 0}
									type={'money'}
									error={sumError}
									description={'Total Donated'}
									totalCards={5}
								/>}
								{this.props.stats && <StatCard
									title={'Projected'}
									stat={monthlyMin}
									loading={!sum && sum !== 0}
									type={'money'}
									error={sumError}
									description={'Monthly Minimum'}
									totalCards={5}
								/>}
							</Flex>
							<Flex row justifyContent="space-between" marginBottom="1em" width="100%">
								<Flex column width="calc(50% - 0.5em)">
									<Card padding="1em">
										<Heading as="h3" margin="0 0 0.5em 0"><Link href="/admin/donations" style={{inherit: 'all'}}>Recent Donations</Link></Heading>
										{this.props.donations && <DynamicTable data={this.props.donations.filter((donation) => donation.payment_state < 3)} columns={[
											{ Header: 'Date', accessor: 'date_added', width: 100, Cell: props => parseDate(props.value),
												sortMethod: (a, b) => {
													return Date.parse(a) > Date.parse(b) ? 1 : -1
												}
											},
											{ Header: 'Project', accessor: 'project_name'},
											{ Header: 'Sum', accessor: 'sum', width: 70, Cell: props => currencyFormat(props.value) },
											{ Header: 'Status', accessor: 'payment_state', width: 70, Cell: props => props.value === 2 ? <i className="fas fa-check" /> : <i className="fas fa-spinner" /> },
										]}
										sortBy={[
											{
												id: "date_added",
												desc: true
											}
										]}
										paginate
										 />}
									</Card>
								</Flex>
								<Flex column width="calc(50% - 0.5em)">
									<Card padding="1em">
										<Heading as="h3" margin="0 0 0.5em 0"><Link href="/admin/projects" style={{inherit: 'all'}}>Active Projects</Link></Heading>
										{this.props.projects && <DynamicTable data={this.props.projects} columns={[
											{ Header: 'Name', accessor: 'name', width: 240 },
											{ Header: '$ Raised', accessor: 'donates_current', Cell: props => currencyFormat(props.value) },
											{ Header: 'End Date', accessor: 'end_date', width: 100, Cell: props => parseDate(props.value) }
										]}
										sortBy={[
											{
												id: "donates_current",
												desc: true
											}
										]}
										onPress={this.clickProject}
										/>}
									</Card>
								</Flex>
							</Flex>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
						<Flex />
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	onGetProjects: PropTypes.func,
	organization: PropTypes.object,
	donations: PropTypes.array,
	projects: PropTypes.array,
	stats: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	statsPending: PropTypes.bool,
	onSelectProject: PropTypes.func,
	refreshToken: PropTypes.func,
	reToken: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		donations: state.adminState.donations,
		projects: state.donateNowState.projects,
		stats: state.statistics,
		token: state.authState.accessToken,
		reToken: state.authState.refreshToken,
		pending: state.adminState.pending,
		statsPending: state.statistics.pending,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetProjects: (orgId) => dispatch(getProjects(orgId)),
		onSelectProject: (project) => dispatch(changeSelectedProject(project)),
		refreshToken: (token) => dispatch(refreshToken(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
